const apiConfig = {
	baseUrl: import.meta.env.VITE_API_GATEWAY_ENDPOINT || `/`,
	websocketBaseUrl: import.meta.env.VITE_WEBSOCKET_ENDPOINT,
	domain: import.meta.env.VITE_AUTH0_DOMAIN,
	client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
	audience: import.meta.env.VITE_AUTH0_AUDIENCE,
	scope:
		'read:current_user update:current_user_metadata openid profile email offline_access',
}

export default apiConfig
